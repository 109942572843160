//  threats list 
const THREATS_LIST = `
    id
    order
    refId
    description
    threatLevel
    alertColor{
        color
        info
        infoKey
    }
    `
const THREATS_DETAILS = `
    id
    order
    refId
    alertColor{
        color
        info
        infoKey
    }
    description
    fullDescription
    impactConfidentiality
    impactIntegrity
    impactAvailability
    threatLevel
    threatExplanation
    lastModified
    archived
    owner{
        id
        firstName
        lastName
        username
    }
    risks{
        id
        description
    }
    created_by{
        id
        username
    }
    `

const RISK_LIST = `
    id
    order
    refId
    description
    fullDescription
    `
export default { THREATS_LIST, RISK_LIST, THREATS_DETAILS }