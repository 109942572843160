




















































































































































import { computed, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '../../shared/mixins/helpers'
import { ParamDTO, ThreatDTO } from '@/dto/backend-response/threatsDTO'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { useToast } from 'vue-toastification/composition'
import utils from '@/shared/mixins/utils'
import threatQuery from '@/shared/queries/threatQueries'
import variables from '@/shared/variables'
import CreateThreat from '@/components/threats/CreateThreat.vue'
import ThreatOperations from '@/components/threats/ThreatOperations.vue'
import threatsData from '@/assets/data/threats-data.json'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import router from '@/router'
import { ThreatsFormDTO } from '@/dto/forms/threatsFormDTO'

export default defineComponent({
    components: { Table, CreateThreat, ThreatOperations },
    props: {
        idfilter: {
            type: Number,
        },
    },
    destroy() {
        this.$destroy()
    },
    setup(props) {
        const threatList: Ref = ref([])
        const threat: Ref<null> = ref(null)
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { menuOpen } = useState(['menuOpen'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const toast = useToast()
        const loading = ref(false)
        const infomationOp: Ref = ref(null)
        const { role } = useState(['role'])
        const displayCreateThreat = ref(false)
        const visibleThreatDetails = ref(false)
        const threatDetails: Ref = ref(null)
        // const threatOperationsComp = ref(null) // commented for later use.
        const visibleLeft = ref(false)
        const tableCmp: Ref = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const threatFilters = threatsData.THREATS_FILTERS
        const totalPages = ref(0)
        const isArchivedList = ref(false)

        /* Enable create new threat button when
           - logged in user role is Employee/ Perium Admin/ Developer admin
           - and is not archived list 
        */
        const isEnableCreateNewThreatButton = computed(() => {
            const roles = [UserRole.EMPLOYEE, UserRole.PERIUM_ADMIN, UserRole.DEVELOPER_ADMIN]
            return roles.includes(role.value) && !isArchivedList.value
        })

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('THREATS_TABLE_COLUMN_NO', language.value),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('THREATS_TABLE_COLUMN_DESCRIPTION', language.value),
                    sort: true,
                    fieldName: 'description',
                    limit:200,
                },
                {
                    header: t('THREATS_TABLE_COLUMN_IMPACT_THREAT_LEVEL', language.value),
                    sort: true,
                    fieldName: 'threatLevel',
                    level: true,
                    levelType: 'threatLevel',
                    info: true,
                    infoContent: t('THREATS_TABLE_COLUMN_IMPACT_THREAT_LEVEL_INFO', language.value),
                },
            ]
        }

        // updating threatList array with additional fields and setting description content
        const toTableFormat = (tempResultData: Array<ThreatDTO>) => {
            threatList.value = []
            tempResultData.map((threat) => {
                let threatCopy = {
                    no: 0,
                    color: '',
                    info: '',
                    infoKey: '',
                    alertColorName: '',
                }
                threatCopy = Object.create(threat) // creating a copy of threat object
                threatCopy.no = utils.padLeft('000', threat.refId) // creating no
                threatCopy.color = utils.getAlertInfo('', threat)?.color
                threatCopy.info = utils.getAlertInfo('', threat)?.info
                threatCopy.infoKey = utils.getAlertInfo('', threat)?.infoKey
                threatCopy.alertColorName = utils.getAlertInfo('', threat)?.alertColorName

                threatList.value.push(threatCopy)
            })
        }

        // get all threats
        const getThreats = async () => {
            loading.value = true
            const getThreatsQuery = `
                query threats($filter: ThreatFilter){
                    threats(filter: $filter){
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items{
                            ${threatQuery.THREATS_LIST}
                        }
                        
                    }
                }
            `

            const threatsVariables = {
                filter: {
                    archived: isArchivedList.value,
                },
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(getThreatsQuery, threatsVariables)
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving threats')
            }
            loading.value = false
            if (!result) {
                return
            }
            // Copy array to be able to sort it in place
            const threats = [...result.data.threats.items]
            // total pages based on perPage value
            totalPages.value = result.data.threats.pageInfo.totalPages
            // display totale records count as filteredRecordCount. This could change based on client's comments in future
            filteredRecordCount.value = result.data.threats.pageInfo.totalCount
            // sort by id (ascending)
            threats.sort((a, b) => (a.refId > b.refId ? 1 : -1))
            // updating threats array with additional fields and setting description content
            toTableFormat(threats)
        }

        // format threat details
        const getFormatedThreatDetails = () => {
            // add threat details color and info
            if (!threatDetails.value) return
            threatDetails.value.color = utils.getAlertInfo('', threatDetails.value).color
            threatDetails.value.info = utils.getAlertInfo('', threatDetails.value).info
            threatDetails.value.infoKey = utils.getAlertInfo('', threatDetails.value).infoKey

            threatDetails.value.no = utils.padLeft('000', threatDetails.value.refId)
            // add `lastRecordNo` and `firstRecordNo` to enable/disable nex/previous buttons
            threatDetails.value.lastRecordNo =
                tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
            threatDetails.value.firstRecordNo =
                tableCmp.value && tableCmp.value.copyOfTableData[0].no
        }

        // get threats by id
        const getThreat = async (id: number) => {
            threatDetails.value = null
            displayCreateThreat.value = false
            const getThreatQuery = `
                query {
                    threat(id: ${id}) {
                        ${threatQuery.THREATS_DETAILS}
                    }
                }
            `
            const result = await apolloClient.getGraphqlData(getThreatQuery)
            if (!result) {
                return
            }
            threatDetails.value = result.data.threat
            getFormatedThreatDetails()
        }

        // go to threat record
        const goToRecord = async (params: { no: string; actionType: string }) => {
            if (!tableCmp.value) {
                return
            }
            /* get the index of selected details on `copyOfTableData`. 
            `threatList` cannot be use here as when the filter/ sort is in use, list is getting updated */
            const record = utils.getRecordByIndex(
                tableCmp.value.copyOfTableData,
                params.actionType,
                params.no,
                tableCmp.value
            )
            // use the id of the record to get next/previous details
            await getThreat(record.id)
        }

        // save while table list is sorted
        const sortableListSave = () => {
            // if the table is sorted (`sortedField` has a value)
            if (!tableCmp.value.sortedField) {
                return
            }
            // Based on the sorted type(asc/desc), sort the refreshed list after saving
            if (tableCmp.value.sortToggle === 'up') {
                threatList.value.sort((a: { [x: string]: number }, b: { [x: string]: number }) =>
                    a[tableCmp.value.sortedField] < b[tableCmp.value.sortedField] ? 1 : -1
                )
            } else {
                threatList.value.sort((a: { [x: string]: number }, b: { [x: string]: number }) =>
                    b[tableCmp.value.sortedField] < a[tableCmp.value.sortedField] ? 1 : -1
                )
            }
        }

        // get all threat's input fields
        const getAllTreatInputs = (threatFormData: ThreatsFormDTO) => {
            return {
                fullDescription: threatFormData.fullDescription,
                description: threatFormData.description,
                impactAvailability: threatFormData.BIV.B === YesNo.YES ? true : false,
                impactIntegrity: threatFormData.BIV.I === YesNo.YES ? true : false,
                impactConfidentiality: threatFormData.BIV.V === YesNo.YES ? true : false,
                threatLevel: threatFormData.threatLevel,
                threatExplanation: threatFormData.threatExplenation,
                risks: threatFormData.riskList,
            }
        }

        // updating threats data
        const saveThreatsData = async (params: ParamDTO) => {
            let mutationQuery: string
            let input
            const threatFormData = params.formData
            if (role.value === UserRole.SUPER_ADMIN) {
                // mutation query for super admin
                mutationQuery = `mutation ($input: UpdateMasterThreatInput!) {
                        updateMasterThreat(id: ${params.id}, input: $input) {
                            status
                        }
                    }`
                input = {
                    fullDescription: threatFormData.fullDescription,
                    description: threatFormData.description,
                    impactAvailability: threatFormData.BIV.B === YesNo.YES ? true : false,
                    impactIntegrity: threatFormData.BIV.I === YesNo.YES ? true : false,
                    impactConfidentiality: threatFormData.BIV.V === YesNo.YES ? true : false,
                }
            } else {
                // mutation query for other users
                mutationQuery = `mutation ($input: ThreatInput!) {
                    updateThreat(id: ${params.id}, input: $input) {
                        status
                    }
                }`
                // update input for the entities which are created by user
                if (params.createdBy) {
                    input = getAllTreatInputs(threatFormData)
                } else {
                    // update input for the entities which are created by organization
                    input = {
                        threatLevel: threatFormData.threatLevel,
                        threatExplanation: threatFormData.threatExplenation,
                    }
                }
            }

            // update data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('THREATS_UPDATE_ERROR_MESSAGE', language.value))
                throw Error('Error while updating threats')
            }
            // other user updates
            if (result.data.updateThreat && result.data.updateThreat.status) {
                await getThreats()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.saveType === 'save-next') {
                    await goToRecord({ no: params.no, actionType: 'next' })
                } else {
                    await getThreat(params.id)
                }

                toast.success(t('THREATS_UPDATE_SUCESS_MESSAGE', language.value))
            } else if (
                // super admin updates
                result.data.updateMasterThreat &&
                result.data.updateMasterThreat.status
            ) {
                await getThreats()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.saveType === 'save-next') {
                    await goToRecord({ no: params.no, actionType: 'next' })
                } else {
                    await getThreat(params.id)
                }
                toast.success(t('THREATS_UPDATE_SUCESS_MESSAGE', language.value))
            } else {
                toast.error(t('THREATS_UPDATE_ERROR_MESSAGE', language.value))
            }
        }

        //  create new threat save
        const saveNewThreat = async (params: ParamDTO) => {
            const threatFormData = params.formData

            const mutationQuery = `mutation ($input: ThreatInput!) {
                        createThreat(input: $input) {
                            status
                        }
                    }`

            const input = getAllTreatInputs(threatFormData)

            // create threat api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('THREATS_CREATE_ERROR_MESSAGE', language.value))
                throw Error('Error while creating threat')
            }
            if (result.data.createThreat && result.data.createThreat.status) {
                toast.success(t('THREAT_CREATE_SUCESS_MESSAGE', language.value))
                await getThreats()
                displayCreateThreat.value = false
            } else {
                toast.error(t('THREATS_CREATE_ERROR_MESSAGE', language.value))
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        //  display therat details sidebar
        const openThreatDetailsPopup = async (id: number) => {
            visibleThreatDetails.value = true
            await getThreat(id)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count: number) => {
            filterCount.value = count
        }

        // set searched records count
        const searchCount = (count: number) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }
        // update table records count
        const updateRecordCount = (recordsCount: number) => {
            filteredRecordCount.value = recordsCount
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close view details entity if it's open
                    if (visibleThreatDetails.value) {
                        visibleThreatDetails.value = false
                    }
                    // close create new entity if it's open
                    if (displayCreateThreat.value) {
                        displayCreateThreat.value = false
                    }
                }
            })
        }

        // update archive state (true = archive, false = unarchive)
        const archive = async (archivalState: boolean) => {
            const mutationQuery = `mutation{
                        updateThreatArchiveState(id:${threatDetails.value.id}, archived: ${archivalState}) {
                            status
                        }
                    }`
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, {})
            } catch (err) {
                archivalState
                    ? toast.error(t('THREAT_ARCHIVE_ERROR_MESSAGE', language.value))
                    : toast.error(t('THREAT_UNARCHIVE_ERROR_MESSAGE', language.value))
                throw Error('Error while archiving/ unarchiving threat')
            }
            if (
                result.data.updateThreatArchiveState &&
                result.data.updateThreatArchiveState.status
            ) {
                archivalState
                    ? toast.success(t('THREAT_ARCHIVE_SUCESS_MESSAGE', language.value))
                    : toast.success(t('THREAT_UNARCHIVE_SUCESS_MESSAGE', language.value))
                visibleThreatDetails.value = false

                await getThreats()
            } else {
                archivalState
                    ? toast.error(t('THREAT_ARCHIVE_ERROR_MESSAGE', language.value))
                    : toast.error(t('THREAT_UNARCHIVE_ERROR_MESSAGE', language.value))
            }
        }
        // view archived list
        const goToArchivedList = () => {
            router.push({ name: 'ArchivedThreats' })
        }

        // make active row if idfilter value is available within initial threatList
        const activeRow = () => {
            if (
                threatList.value.find(
                    (val: { id: number | undefined }) => val.id === props.idfilter
                )
            ) {
                const rowId = threatList.value.find(
                    (val: { id: number | undefined }) => val.id === props.idfilter
                ).no
                tableCmp.value.activeRow(rowId)
            }
        }

        /* Enable archived list button when
           - logged in user role is Admin/ Perium Admin/ Developer admin
           - and the page is not archived list 
        */
        const isArchivedListBtnEnabled = computed(() => {
            const roles = [UserRole.ADMIN, UserRole.PERIUM_ADMIN, UserRole.DEVELOPER_ADMIN]
            return !isArchivedList.value && roles.includes(role.value)
        })

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )
        // watch for url change
        watch(
            () => router.app.$route,
            () => {
                const path = router.app.$route.path
                // extract `archived-list` string from path
                isArchivedList.value = path.split('/').includes('archived-list')
            },
            {
                immediate: true,
            }
        )
        onMounted(async () => {
            closeSidebarEsc()
            // when navigating from heatmap, open selected row details
            if (props.idfilter) {
                await getThreats()
                // active selected row
                activeRow()
                // open details page
                openThreatDetailsPopup(props.idfilter)
            } else {
                await getThreats()
            }
        })

        return {
            threatList,
            threat,
            getThreat,
            t,
            language,
            tableHeaders,
            saveThreatsData,
            loading,
            infomationOp,
            toggleInformation,
            displayCreateThreat,
            getThreats,
            role,
            variables,
            openThreatDetailsPopup,
            visibleThreatDetails,
            threatDetails,
            goToRecord,
            // threatOperationsComp, // commented for later use.
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            tableCmp,
            saveNewThreat,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            threatFilters,
            UserRole,
            totalPages,
            archive,
            goToArchivedList,
            isArchivedList,
            isEnableCreateNewThreatButton,
            isArchivedListBtnEnabled,
        }
    },
})
