


















































































import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import LevelBar from '@/shared/components/LevelBar.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import ThreatDetails from './ThreatDetails.vue'
import removeMd from 'remove-markdown'
import threatLevels from '@/assets/data/threats-data.json'
import variables from '@/shared/variables'
import Heatmap from '@/shared/components/Heatmap.vue'
import Confirmation from '@/shared/components/Confirmation.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
        isArchivedList: {
            type: Boolean,
        },
    },
    components: {
        LevelBar,
        ThreatDetails,
        Heatmap,
        Confirmation,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labels = ref({})
        const copyOfEntityDetails = ref({})
        const levelExplanation: Ref = ref('')
        const TabviewCmp = ref(null)
        const nameInitals: Ref = ref('')
        const toggleKebabMenu = ref(null)
        const createdByUser = ref(false)

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field: { toggle: (arg0: unknown) => void }) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.owner && props.entityDetails.owner.firstName) +
                ' ' +
                (props.entityDetails.owner && props.entityDetails.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)

            // threat level explanation
            levelExplanation.value = threatLevels.THREAT_LEVELS.find(
                (val) => val.SCORE === props.entityDetails.threatLevel
            )?.VALUE

            const createdByUsername =
                props.entityDetails.created_by && props.entityDetails.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            labels,
            copyOfEntityDetails,
            levelExplanation,
            TabviewCmp,
            removeMd,
            nameInitals,
            variables,
            toggleInformation,
            toggleKebabMenu,
            createdByUser,
        }
    },
})
